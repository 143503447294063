import request from "../api/reques";
export function login(data) {
  return request.post("/user/login", data);
}

export function logout() {
  return request.get("/user/logout");
}

export function reportList(params) {
  return request.get("/user/reportList", { params });
}

export function userInfo(params) {
  return request.get("/user/userInfo", { params });
}

export function addReport(data) {
  return request.post("/user/addReport", data);
}

export function editPassword(data) {
  return request.post("/user/editPassword", data);
}

export function uploadAvatar(data) {
  return request.post("/user/uploadAvatar", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

//删除报事
export function delReport(params) {
  return request.delete("/user/delReport", {params});
}
